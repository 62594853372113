import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'

import { useMutation, useQuery } from '@apollo/client'
import {
  Form,
  FormIdentifier,
} from '@app/account/components/forms/FieldFactory'
import PasswordForm from '@app/account/components/forms/PasswordForm'
import RegisterTermsForm from '@app/account/components/forms/RegisterTermsForm'
import REGISTER_INVITATION_MUTATION from '@app/account/graphql/RegisterInvitationMutation.gql'
import VALID_INVITATION_TOKEN_QUERY from '@app/account/graphql/ValidInvitationTokenQuery.gql'
import { useI18n } from '@app/configuration/contexts/I18nContext'
import { useCustomer } from '@app/configuration/hooks/useCustomer'
import {
  InvitationTokenResponse,
  TermsAndAgreementsConfig,
} from '@app/graphql-types/Account'
import useServerError from '@app/src/hooks/useServerError'
import { getQueryParamValue } from '@app/src/lib/uri'

export interface Props {
  forms: Form[]
}

const VALID_AGREEMENT_KEY_NAMES = [
  'termsOfService',
  'privacyPolicy',
  'informedConsent',
  'noticeOfPrivacyPractices',
  'patientFinancialResponsibility',
]

const hasTermsAndAgreements = (response: InvitationTokenResponse) => {
  // The response is not of the correct type. bail
  if (response?.__typename !== 'TermsAndAgreementsConfig') {
    return false
  }

  // Terms and agreements is not enabled. bail
  if (!response.termsAndAgreementsEnabled) {
    return false
  }

  // agreements in config that have truthy values
  const availableAgreements = Object.keys(response).filter(
    (key) => response[key],
  )
  // At least one of the above necessary values was properly configured to
  // display the new agreements form
  // TODO: Should we be returning the "new" terms_and_agreements_enabled flag as an environment variable from the config API?
  return VALID_AGREEMENT_KEY_NAMES.some((val: string) =>
    availableAgreements.includes(val),
  )
}

const RegisterInvitationPage = () => {
  const i18n = useI18n()
  const { pageTitle } = useCustomer()
  const [index, setIndex] = React.useState(0)

  const [agreementsConfig, setAgreementsConfig] =
    React.useState<InvitationTokenResponse>()

  const { clearServerError, serverError, setServerError } = useServerError()
  const token = getQueryParamValue('invitation_token')

  const fromExternalSource =
    getQueryParamValue('invitation_from_external_source')?.toLowerCase() ===
    'true'
  const navigate = useNavigate()

  useQuery(VALID_INVITATION_TOKEN_QUERY, {
    onCompleted: (data) => {
      if (data?.validInvitationToken) {
        const { statusCode, message } = data.validInvitationToken
        if (statusCode === 422 && message?.length) {
          navigate('/invitation/resend?invalid_token=true', {
            state: { message },
          })
        } else {
          if (!agreementsConfig) {
            setAgreementsConfig(data.validInvitationToken)
          }
        }
      }
    },
    variables: { token, fromExternalSource },
  })

  const [registerUser, { error }] = useMutation(REGISTER_INVITATION_MUTATION)

  const handleSubmit = React.useCallback(
    (formData: Record<string, string>) => {
      const { password, passwordConfirmation, ...checkboxes } = formData

      const termFields = [
        'agreeToTermsAndPrivacyPolicy',
        'agreeToTerms',
        'agreeToPrivacyPolicy',
        'agreeToInformedConsentAndPrivacyPractices',
        'agreeToInformedConsent',
        'agreeToPrivacyPractices',
        'agreeToFinancialResponsibility',
        'agreeToTextMessageMarketingAgreement',
      ]
      const checkboxValues = {}

      termFields.forEach((field) => {
        checkboxValues[field] = checkboxes[field] === 'true' || false
      })

      // This is a front-end only key that is responsible for checking both agreeToTerms and agreeToPrivacyPolicy
      if (checkboxValues['agreeToTermsAndPrivacyPolicy'] === true) {
        checkboxValues['agreeToTerms'] = true
        checkboxValues['agreeToPrivacyPolicy'] = true
      }

      // This is a front-end only key that is responsible for checking both agreeToInformedConsent and agreeToPrivacyPractices
      if (
        checkboxValues['agreeToInformedConsentAndPrivacyPractices'] === true
      ) {
        checkboxValues['agreeToInformedConsent'] = true
        checkboxValues['agreeToPrivacyPractices'] = true
      }

      // Delete the front-end only keys in order to satisfy the back end's GQL mutation signature
      delete checkboxValues['agreeToInformedConsentAndPrivacyPractices']
      delete checkboxValues['agreeToTermsAndPrivacyPolicy']

      const input = {
        ...checkboxValues,
        id: (agreementsConfig as TermsAndAgreementsConfig)?.id,
        password,
        passwordConfirmation,
        invitationToken: token,
      }

      registerUser({
        onCompleted: (data) => {
          if (data?.registerInvitation) {
            const {
              __typename,
              accessToken,
              mfaToken,
              phoneNumber,
              redirectPath,
            } = data.registerInvitation

            if (__typename === 'Mfa') {
              navigate(redirectPath, {
                state: {
                  mfaToken,
                  phone: phoneNumber,
                },
              })
              return
            }

            if (accessToken) {
              window.location.href = '/'
            }
          }
        },
        variables: {
          input,
        },
      })
    },
    [agreementsConfig, navigate, registerUser, token],
  )

  const handleTermsSubmit = React.useCallback(
    (formData: Record<string, string>) => {
      switch (index) {
        case 0:
          setIndex((prevIndex) => prevIndex + 1)
          break
        case 1:
          handleSubmit(formData)
          break
        default:
          break
      }
    },
    [handleSubmit, index],
  )

  React.useEffect(() => {
    if (error?.graphQLErrors.length) {
      const err: any = error.graphQLErrors[0]
      setServerError({ message: err.message, errors: err.fields })
    } else {
      clearServerError()
    }
  }, [clearServerError, error, setServerError])

  if (!agreementsConfig) {
    return null
  }

  return (
    <>
      <Helmet
        title={`${pageTitle} - ${i18n.t('registerInvitationPage.pageTitle')}`}
      />
      {hasTermsAndAgreements(agreementsConfig) ? (
        <RegisterTermsForm
          agreementsConfig={agreementsConfig}
          clearServerError={clearServerError}
          handleSubmit={handleTermsSubmit}
          serverError={serverError}
          viewIndex={index}
        />
      ) : (
        <PasswordForm
          agreementsConfig={agreementsConfig}
          clearServerError={clearServerError}
          formIdentifier={FormIdentifier.REGISTER_INVITATION}
          handleSubmit={handleSubmit}
          serverError={serverError}
        />
      )}
    </>
  )
}

export default RegisterInvitationPage
