import React from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router'

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import {
  Form,
  FormIdentifier,
} from '@app/account/components/forms/FieldFactory'
import FormBuilder from '@app/account/components/forms/FormBuilder'
import { PageHeader } from '@app/account/components/pages/PageParts'
import RESET_PASSWORD_MUTATION from '@app/account/graphql/ResetPasswordMutation.gql'
import VALID_RESET_PASSWORD_TOKEN_QUERY from '@app/account/graphql/ValidResetPasswordTokenQuery.gql'
import { useI18n } from '@app/configuration/contexts/I18nContext'
import { useCustomer } from '@app/configuration/hooks/useCustomer'
import {
  BasicType,
  ElementType,
} from '@app/src/components/forms/FormElementParts'
import styled from '@app/src/constants/theme'
import { FormData } from '@app/src/hooks/useForm'
import useServerError from '@app/src/hooks/useServerError'

const INVALID_TOKEN_ERROR_CLASSNAME = 'InvalidTokenError'

const form = (
  translator: (key: string, replacementMap?: Record<string, string>) => any,
): Form => ({
  fields: [
    {
      label: translator('newPassword'),
      name: 'password',
      type: ElementType.PASSWORD,
    },
    {
      label: translator('newPasswordConfirmation'),
      name: 'passwordConfirmation',
      type: ElementType.PASSWORD,
    },
    {
      label: translator('changeMyPassword'),
      type: BasicType.SUBMIT,
    },
    {
      label: translator('alreadyHaveAccountLink'),
      type: BasicType.FOOTER,
    },
    {
      fontSize: 13,
      label: translator('needInvitationLink'),
      type: BasicType.FOOTER,
    },
  ],
  type: FormIdentifier.CHANGE_PASSWORD,
})

const ResetPasswordPage = () => {
  const { pageTitle } = useCustomer()
  const i18n = useI18n()
  const [searchParams] = useSearchParams()
  const { clearServerError, serverError, setServerError } = useServerError()
  const [mfa, setMfa] = React.useState<any>({})
  const [isResetValid, setIsResetValid] = React.useState(true)

  const changePasswordText = i18n.t('changeYourPassword')
  const resetPasswordToken = searchParams.get('reset_password_token')
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION)
  const [validTokenQuery, { loading }] = useLazyQuery(
    VALID_RESET_PASSWORD_TOKEN_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        token: resetPasswordToken,
      },
    },
  )

  React.useEffect(() => {
    validTokenQuery({
      onCompleted: (data) => {
        setMfa(data.validResetPasswordToken)
      },
      onError: (_error: ApolloError) => {
        setIsResetValid(false)
      },
    })
  }, []) //eslint-disable-line

  const handleSubmitForm = React.useCallback(
    (formData: FormData) => {
      resetPassword({
        onCompleted: (data) => {
          if (data?.resetPassword) {
            window.location.href = '/'
          }
        },
        onError: (error: ApolloError) => {
          if ((error?.graphQLErrors ?? []).length) {
            const err: any = error.graphQLErrors[0]
            setServerError({ message: err.message, errors: err.fields })
          } else {
            clearServerError()
          }
        },
        variables: {
          ...formData,
          resetPasswordToken,
        },
      })
    },
    [clearServerError, resetPassword, resetPasswordToken, setServerError],
  )

  if (loading) {
    return null
  }

  const renderedForm = form(i18n.t)
  let description = ''
  if (isResetValid) {
    description = i18n.t('passwordRules')

    if (mfa.isMfaEnabled) {
      renderedForm.fields.splice(0, 0, {
        label: i18n.t('mfa.securityCode'),
        name: 'mfaCode',
        type: ElementType.INTEGER,
      })
    }
  } else {
    renderedForm.fields.splice(0, 3, {
      label: `<p class="${INVALID_TOKEN_ERROR_CLASSNAME}">${i18n.t('resetPasswordPage.invalidToken')}</p>`,
      type: BasicType.PLAINTEXT,
    })
  }

  return (
    <Wrapper>
      <Helmet title={`${pageTitle} - ${changePasswordText}`} />

      <PageHeader>{changePasswordText}</PageHeader>

      {mfa.mfaHeaderText && <p className="MfaHeader">{mfa.mfaHeaderText}</p>}

      <FormBuilder
        description={description}
        form={renderedForm}
        key={renderedForm.type}
        onChange={clearServerError}
        onSubmit={handleSubmitForm}
        serverError={serverError}
        showRequiredFieldsExplanation={isResetValid}
      />
    </Wrapper>
  )
}

const Wrapper = styled.article({
  '.footer': {
    width: 'auto',
  },
  '.FormBuilder > p > ul': {
    paddingLeft: '16px !px',
  },
  '.FormError': {
    marginBottom: 0,
  },
  [`.${INVALID_TOKEN_ERROR_CLASSNAME}`]: {
    textAlign: 'center',
  },
  '.MfaHeader': {
    marginBottom: '1.5rem',
    textAlign: 'center',
  },
})

export default ResetPasswordPage
