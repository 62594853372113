mutation MfaSendVerificationCode(
  $mfaToken: String!
  $phone: String!
  $mfaChannel: MfaSendChannelEnum
) {
  mfaSendVerificationCode(
    mfaToken: $mfaToken
    phone: $phone
    mfaChannel: $mfaChannel
  ) {
    mfaChannel
    mfaToken
    phoneNumber
    redirectPath
  }
}
