import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'

import { useMutation } from '@apollo/client'

import { useI18n } from '../../../configuration/contexts/I18nContext'
import useCustomer from '../../../configuration/hooks/useCustomer'
import {
  BasicType,
  ElementType,
  Field,
} from '../../../src/components/forms/FormElementParts'
import { FormData } from '../../../src/hooks/useForm'
import { clearLocalPHI } from '../../../src/hooks/useLocalState'
import useServerError from '../../../src/hooks/useServerError'
import FormBuilder from '../../components/forms/FormBuilder'
import SignInEmailMutation from '../../graphql/SignInEmailMutation.gql'
import SignInMutation from '../../graphql/SignInMutation.gql'
import FooterDiv from '../elements/FooterDiv'
import TermsAndPrivacyPolicy from '../elements/TermsAndPrivacyPolicy'
import { Form, FormIdentifier } from '../forms/FieldFactory'

const getBaseForm = (
  translator: (key: string, replacementMap?: Record<string, string>) => any,
): Form => {
  const fields: Field[] = []

  return {
    fields,
    header: translator('signInPage.header'),
    type: FormIdentifier.SIGN_IN,
  }
}

const SignInPage = () => {
  const i18n = useI18n()
  const form = getBaseForm(i18n.t)
  const navigate = useNavigate()

  // Determine if we need to display the terms and privacy policy links
  const {
    clientId,
    clientSecret,
    faq,
    pageTitle,
    privacyPolicy,
    registration,
    termsOfService,
  } = useCustomer()

  const [signInEmail] = useMutation(SignInEmailMutation)
  const [signIn, { data, error }] = useMutation(SignInMutation)
  const { clearServerError, serverError, setServerError } = useServerError()
  const [usePassword, setUsePassword] = React.useState(false)
  const inputEmail = React.useRef('')

  const emailFieldEventListener = () => {
    setUsePassword(false)
  }

  const addTermLinks = React.useCallback(
    (fields: Field[]) => {
      let termLinks = ''
      if (termsOfService && privacyPolicy) {
        termLinks = i18n.t('termsAndPrivacyLink', {
          termsHref: termsOfService,
          privacyHref: privacyPolicy,
        })
      } else if (termsOfService) {
        termLinks = i18n.t('termsLink', { href: termsOfService })
      } else if (privacyPolicy) {
        termLinks = i18n.t('privacyLink', { href: privacyPolicy })
      }

      if (termLinks) {
        fields.push({
          fontSize: 12,
          largeMarginTop: true,
          label: `${i18n.t('signInPage.agreeText')} ${termLinks}`,
          type: BasicType.PLAINTEXT,
        })
      }
    },
    [termsOfService, privacyPolicy, i18n],
  )

  const addRegistrationAndInvitationLinks = React.useCallback(
    (fields: Field[]) => {
      // Determine which registration link to display
      const registerHref = registration || '/register'
      const registerLink = i18n.t('signInPage.registerLink', { registerHref })
      fields.push({
        label: registerLink,
        type: BasicType.FOOTER,
      })

      // Render resend invitation link
      fields.push({
        fontSize: 13,
        label: i18n.t('needInvitationLink'),
        type: BasicType.FOOTER,
      })
    },
    [registration, i18n],
  )

  const addFaqLinks = React.useCallback(
    (fields: Field[]) => {
      faq &&
        fields.push({
          label: `<a href="${faq}" rel="noopener noreferrer" target="_blank">${i18n.t(
            'faq',
          )}</a>`,
          type: BasicType.FOOTER,
        })
    },
    [faq, i18n],
  )

  const addEmailField = React.useCallback(
    (fields: Field[]) => {
      fields.push({
        label: i18n.t('email'),
        name: 'email',
        placeholder: i18n.t('formPlaceholders.email'),
        type: ElementType.EMAIL,
      })
    },
    [i18n],
  )

  const addSubmitButton = React.useCallback(
    (fields: Field[]) => {
      fields.push({
        label: i18n.t('signIn'),
        type: BasicType.SUBMIT,
      })
    },
    [i18n],
  )

  const formFields = React.useMemo(() => {
    let fields: Field[] = []

    addEmailField(fields)
    addTermLinks(fields)
    addSubmitButton(fields)
    addRegistrationAndInvitationLinks(fields)
    addFaqLinks(fields)

    return fields
  }, [
    addEmailField,
    addTermLinks,
    addSubmitButton,
    addRegistrationAndInvitationLinks,
    addFaqLinks,
  ])

  React.useEffect(clearLocalPHI, [])

  React.useEffect(() => {
    if (error && error.graphQLErrors.length) {
      const err: any = error.graphQLErrors[0]
      setServerError({ message: err.message, errors: err.fields })
    } else {
      clearServerError()
    }
  }, [clearServerError, error, setServerError])

  React.useEffect(() => {
    const signInData = data && data.signIn
    const accessToken = signInData?.accessToken // only available with AccessTokenType
    // this is because we could have an AccessTokenType or a MfaType
    const redirectUri = signInData?.redirectUri || signInData?.redirectPath

    if (accessToken) {
      window.location.href = '/'
    } else if (redirectUri) {
      if (redirectUri.indexOf('/') === 0) {
        navigate(redirectUri, {
          state: {
            mfaChannel: signInData?.mfaChannel,
            mfaToken: signInData?.mfaToken,
            phone: signInData?.phoneNumber,
          },
        })
      }
    }
  }, [data, navigate])

  React.useEffect(() => {
    const emailFieldElement = document.querySelector('input#email')

    if (!emailFieldElement) {
      return
    }

    if (usePassword) {
      emailFieldElement.addEventListener('keyup', emailFieldEventListener)
    }

    return () => {
      emailFieldElement.removeEventListener('keyup', emailFieldEventListener)
    }
  }, [usePassword, setUsePassword])

  const handleSubmitForm = (formData: FormData) => {
    if (usePassword) {
      handleSubmitFormWithPassword(formData)
    } else {
      handleSubmitFormWithoutPassword(formData)
    }
  }

  const handleEmailSignInSuccess = async (data, formData) => {
    const ssoConfig = data?.signInEmail?.ssoConfig
    if (ssoConfig) {
      const email = formData?.email
      const queryParamChar =
        new URL(ssoConfig.signInUrl).searchParams.size > 0 ? '&' : '?'
      const redirectUrl = ssoConfig.signInUrl.concat(
        `${queryParamChar}email=${email}`,
      )
      window.location.href = redirectUrl
    } else {
      setUsePassword(true)
    }
  }

  const handleSubmitFormWithoutPassword = React.useCallback(
    (formData: FormData) => {
      inputEmail.current = formData?.email
      signInEmail({
        variables: {
          email: formData.email.trim(),
        },
      })
        .then(({ data }) => handleEmailSignInSuccess(data, formData))
        .catch((_e) => {
          console.log(_e)
        })
    },
    [signInEmail],
  )

  const handleSubmitFormWithPassword = React.useCallback(
    (formData: FormData) => {
      signIn({
        variables: {
          ...formData,
          clientId,
          clientSecret,
        },
      })
    },
    [clientId, clientSecret, signIn],
  )

  const addPasswordFields = React.useCallback(() => {
    const passwordFields: Field[] = [
      {
        label: i18n.t('password'),
        name: 'password',
        type: ElementType.PASSWORD,
      },
      {
        fontSize: 12,
        label: i18n.t('signInPage.forgotPasswordLink'),
        textAlign: 'right',
        type: BasicType.PLAINTEXT,
      },
    ]

    if (usePassword) {
      passwordFields.forEach((field, i) => {
        form.fields.splice(1 + i, 0, field)
      })
    }
  }, [form.fields, usePassword, i18n])

  const udpateEmailField = React.useCallback(() => {
    if (inputEmail.current !== '') {
      form.fields[0].value = inputEmail.current
    }
  }, [form.fields])

  const addFieldsToForm = () => {
    formFields.forEach((field) => {
      form.fields.push(field)
    })

    addPasswordFields()
    udpateEmailField()
  }

  addFieldsToForm()

  return (
    <React.Fragment>
      <Helmet title={`${pageTitle} - ${i18n.t('signIn')}`} />

      <FormBuilder
        description={i18n.t('signInPage.welcomeBack')}
        form={form}
        key={form.type}
        onChange={clearServerError}
        onSubmit={handleSubmitForm}
        serverError={serverError}
      />
      <FooterDiv>
        <TermsAndPrivacyPolicy />
      </FooterDiv>
    </React.Fragment>
  )
}

export default SignInPage
