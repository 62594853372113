import { useApolloClient } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { Helmet } from 'react-helmet'

import FooterDiv from '@app/account/components/elements/FooterDiv'
import { Form } from '@app/account/components/forms/FieldFactory'
import FormBuilder from '@app/account/components/forms/FormBuilder'
import REGISTER_MUTATION from '@app/account/graphql/RegisterMutation.gql'
import TRACK_EVENT_MUTATION from '@app/account/graphql/TrackEventMutation.gql'
import { ConfigurationContext } from '@app/configuration/contexts/ConfigurationContext'
import { useI18n } from '@app/configuration/contexts/I18nContext'
import { useConfiguration } from '@app/configuration/hooks/useConfiguration'
import { useCustomer } from '@app/configuration/hooks/useCustomer'
import Modal from '@app/src/components/dialogs/Modal'
import {
  Dialog,
  Content as DialogContent,
  Header as DialogHeader,
} from '@app/src/components/dialogs/SharedDialogParts'
import { BasicType, Field } from '@app/src/components/forms/FormElementParts'
import { ISO_EXTENDED } from '@app/src/constants/formats'
import { FormData } from '@app/src/hooks/useForm'
import { clearLocalPHI } from '@app/src/hooks/useLocalState'

export interface Props {
  client?: any
  forms?: Form[]
}

interface ServerError {
  errors?: { [key: string]: string } | {}
  message?: string | null
}

const REGISTER_PAGE_VIEW = 'register_page_view_event'
const REGISTER_INTERACTION_EMAIL = 'register_interaction_email'
const REGISTER_INTERACTION_DOB = 'register_interaction_dob'
const REGISTER_INTERACTION_FIRST_NAME = 'register_interaction_first_name'
const REGISTER_INTERACTION_LAST_NAME = 'register_interaction_last_name'
const REGISTER_INTERACTION_MEMBER_ID = 'register_interaction_member_id'
const REGISTER_INTERACTION_ZIPCODE = 'register_interaction_zipcode'

export const getNormalizedData: any = (form: Form, formData: FormData) => {
  const normalizedData = {}
  Object.keys(formData).forEach((key) => {
    const value = formData[key]
    const field = form.fields.find((f: Field) => f.name === key)
    if (field?.submitOverride) {
      const override = field.submitOverride
      normalizedData[key] = value
        .replace(new RegExp(override.pattern), override.replace)
        .trim()
    } else {
      normalizedData[key] = value.trim()
    }
  })

  normalizedData['onboardStrategy'] = form.onboardStrategy
  return normalizedData
}

export const getOverriddenMemberData = (form: Form, formData: FormData) => {
  if (form.memberIdOverride) {
    let memberId = form.memberIdOverride
    Object.keys(formData).forEach((key) => {
      const value = formData[key]
      memberId = memberId.replace(key, value?.trim())
    })
    formData.memberId = memberId
  }
  formData.memberId = formData.memberId?.trim()
  return formData
}

const determineFormErrors = (
  formData: FormData,
  translator: (key: string) => any,
): ServerError | null => {
  const isDobValid = dayjs(formData.dob, ISO_EXTENDED, true).isValid()

  return isDobValid
    ? null
    : {
        message: translator('registrationPage.invalidFormInfo'),
        errors: { dob: translator('registrationPage.dobInvalid') },
      }
}

// client and forms props are only explicitly set from tests
const RegistrationPage: React.FC<Props> = ({
  client: initialClient,
  forms: initialForms,
}) => {
  let client = useApolloClient()
  const { registrationConfig } = React.useContext(ConfigurationContext) || {}
  const { isWebUserAgent } = useConfiguration()
  const { faq, pageTitle } = useCustomer()
  const i18n = useI18n()

  const forms = React.useMemo(() => {
    return initialForms || registrationConfig || []
  }, [initialForms, registrationConfig])

  const [hasInteractedWithDob, setHasInteractedWithDob] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [hasInteractedWithEmail, setHasInteractedWithEmail] =
    React.useState(false)
  const [hasInteractedWithFirstName, setHasInteractedWithFirstName] =
    React.useState(false)
  const [hasInteractedWithLastName, setHasInteractedWithLastName] =
    React.useState(false)
  const [hasInteractedWithMemberId, setHasInteractedWithMemberId] =
    React.useState(false)
  const [hasInteractedWithZipcode, setHasInteractedWithZipcode] =
    React.useState(false)
  const [index, setIndex] = React.useState(0)
  const [modalData, setModalData] = React.useState<
    | {
        body: string
        header: string
        trackEventName?: string
      }
    | undefined
  >()
  const [serverError, setServerError] = React.useState<ServerError>({
    message: null,
    errors: {},
  })
  const formData = React.useRef({})

  const handleChangeForm = React.useCallback(
    (obj: FormData) => {
      const key = Object.keys(obj)?.[0]
      const value = obj[key]
      formData.current = { ...formData.current, [key]: value }
      if (serverError.message) {
        setServerError({ message: null, errors: {} })
      }
    },
    [serverError.message],
  )

  const sendTrackEvent = React.useCallback(
    (eventName: string) => {
      client.mutate({
        mutation: TRACK_EVENT_MUTATION,
        variables: {
          eventName,
        },
      })
    },
    [client],
  )

  const handleClickBack = React.useCallback(() => {
    if (index > 0) {
      setIndex(index - 1)
      sendTrackEvent(REGISTER_PAGE_VIEW)
      setServerError({ message: null, errors: {} })
    }
  }, [index, sendTrackEvent])

  const serverErrorResponse = () => {
    setIsSubmitting(false)
    setServerError({
      errors: {},
      message: '<h2>Something went wrong</h2>Please try again.',
    })
  }

  const handleSubmitForm = React.useCallback(
    (formData: FormData) => {
      const formErrors = determineFormErrors(formData, i18n.t)

      if (formErrors) {
        setServerError(formErrors)
        return
      }

      setIsSubmitting(true)
      const getNextIndex = () => {
        let newIndex = index + 1
        if (newIndex === forms.length) {
          newIndex = 0
        }
        return newIndex
      }
      const serverSuccessResponse = ({ errors }: { errors?: any }) => {
        setIsSubmitting(false)
        if (errors) {
          const hasFieldErrors =
            Object.keys(errors?.[0]?.fields ?? []).length > 0
          setServerError({
            errors: hasFieldErrors ? errors[0].fields : {},
            message: errors[0].message,
          })
        } else {
          setIndex(getNextIndex())
          sendTrackEvent(REGISTER_PAGE_VIEW)
        }
      }
      const submitRegisterMutation = (formData: FormData) => {
        client
          .mutate({
            mutation: REGISTER_MUTATION,
            variables: {
              input: JSON.stringify(formData),
            },
          })
          .then(serverSuccessResponse)
          .catch(serverErrorResponse)
      }
      const form = forms[index]
      const normalizedData = getNormalizedData(form, formData)
      const serverData = getOverriddenMemberData(form, normalizedData)
      submitRegisterMutation(serverData)
    },
    [client, forms, i18n.t, index, sendTrackEvent],
  )

  const handleCloseModal = React.useCallback(() => {
    setModalData(undefined)
  }, [])

  React.useEffect(clearLocalPHI, [])

  // track initial page view
  React.useEffect(() => {
    sendTrackEvent(REGISTER_PAGE_VIEW)
  }, [sendTrackEvent])

  React.useEffect(() => {
    if (initialClient) {
      client = initialClient // eslint-disable-line
    }

    const handleClickPage = (e: Event) => {
      if (e.target && e.target instanceof HTMLAnchorElement) {
        const href = e.target.getAttribute('href')
        if (href && href.indexOf('#modal-') === 0) {
          e.preventDefault()
          const form = forms[index]
          const fieldName = href.split('-')[1]
          const field = form.fields.find((f: Field) => f.name === fieldName)
          if (field && field.modal) {
            setModalData(field.modal)
            if (field.modal.trackEventName) {
              sendTrackEvent(field.modal.trackEventName)
            }
          }
        }
      }
      if (!hasInteractedWithDob) {
        const dobEl = document && document.querySelector('.DobElement.dob')
        if (dobEl && e.target instanceof Element && dobEl.contains(e.target)) {
          sendTrackEvent(REGISTER_INTERACTION_DOB)
          setHasInteractedWithDob(true)
        }
      }
    }

    const handleKeyUpPage = (e: Event) => {
      if (!hasInteractedWithDob) {
        const dobEl = document && document.querySelector('.DobElement.dob')
        if (dobEl && e.target instanceof Element && dobEl.contains(e.target)) {
          sendTrackEvent(REGISTER_INTERACTION_DOB)
          setHasInteractedWithDob(true)
        }
      }
      if (!hasInteractedWithEmail) {
        const lnEl = document && document.querySelector('.EmailElement.email')
        if (lnEl && e.target instanceof Element && lnEl.contains(e.target)) {
          sendTrackEvent(REGISTER_INTERACTION_EMAIL)
          setHasInteractedWithEmail(true)
        }
      }

      if (!hasInteractedWithFirstName) {
        const lnEl =
          document && document.querySelector('.TextElement.firstName')
        if (lnEl && e.target instanceof Element && lnEl.contains(e.target)) {
          sendTrackEvent(REGISTER_INTERACTION_FIRST_NAME)
          setHasInteractedWithFirstName(true)
        }
      }
      if (!hasInteractedWithLastName) {
        const lnEl = document && document.querySelector('.TextElement.lastName')
        if (lnEl && e.target instanceof Element && lnEl.contains(e.target)) {
          sendTrackEvent(REGISTER_INTERACTION_LAST_NAME)
          setHasInteractedWithLastName(true)
        }
      }
      if (!hasInteractedWithMemberId) {
        const mIdEl =
          document && document.querySelector('.TextElement.memberId')
        if (mIdEl && e.target instanceof Element && mIdEl.contains(e.target)) {
          sendTrackEvent(REGISTER_INTERACTION_MEMBER_ID)
          setHasInteractedWithMemberId(true)
        }
      }
      if (!hasInteractedWithZipcode) {
        const zipEl =
          document && document.querySelector('.ZipcodeElement.zipcode')
        if (zipEl && e.target instanceof Element && zipEl.contains(e.target)) {
          sendTrackEvent(REGISTER_INTERACTION_ZIPCODE)
          setHasInteractedWithZipcode(true)
        }
      }
    }

    document.body.addEventListener('click', handleClickPage)
    document.body.addEventListener('touchend', handleClickPage)
    document.body.addEventListener('keyup', handleKeyUpPage)
    return () => {
      document.body.removeEventListener('click', handleClickPage)
      document.body.removeEventListener('touchend', handleClickPage)
      document.body.removeEventListener('keyup', handleKeyUpPage)
    }
  }, [
    hasInteractedWithDob,
    hasInteractedWithEmail,
    hasInteractedWithFirstName,
    hasInteractedWithLastName,
    hasInteractedWithMemberId,
    hasInteractedWithZipcode,
    sendTrackEvent,
  ])

  if (forms.length === 0) {
    return null
  }

  const form = forms[index]

  // Hide footer fields on mobile devices
  // https://cirrusmd.atlassian.net/browse/PLAT-1927
  if (!isWebUserAgent) {
    form.fields = form.fields.filter((field) => field.type !== BasicType.FOOTER)
  }

  return (
    <React.Fragment>
      <Helmet
        title={`${pageTitle} - ${i18n.t('registrationPage.pageTitle')}`}
      />
      <FormBuilder
        description={
          index === 0 ? i18n.t('registrationPage.welcome') : undefined
        }
        form={form}
        key={form.type}
        isSubmitting={isSubmitting}
        onChange={handleChangeForm}
        onClickBack={handleClickBack}
        onSubmit={handleSubmitForm}
        serverError={serverError}
        showFieldIcons={false}
        showRequiredFieldsExplanation={index === 0}
      />
      {faq && (
        <FooterDiv>
          <a href={faq} rel="noopener noreferrer" target="_blank">
            {i18n.t('faq')}
          </a>
        </FooterDiv>
      )}
      <Modal isOpen={!!modalData} onRequestClose={handleCloseModal}>
        <Dialog>
          <DialogHeader>{modalData?.header}</DialogHeader>
          <DialogContent>{modalData?.body}</DialogContent>
        </Dialog>
      </Modal>
    </React.Fragment>
  )
}

export default RegistrationPage
