import debounce from 'lodash.debounce'
import React from 'react'
import { Outlet, useNavigate } from 'react-router'

import { useCustomer } from '../../../configuration/hooks/useCustomer'
import { CustomerLogo } from '../../../src/components/elements/CustomerLogo'
import {
  BlobsIcon,
  BlobsSmallIcon,
} from '../../../src/components/elements/Icons'
import { AccessibilityNavControls } from '../../../src/components/modules/AccessibilityNavControls'
import styled, { MQ_TABLET, themeNormal } from '../../../src/constants/theme'
import { useFontSizeToggle } from '../../../src/hooks/useFontSizeToggle'
import { hexToHsl } from '../../../src/lib/color'

interface LayoutProps {
  children?: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const navigate = useNavigate()
  const { cirrusLogo, key, primaryColor } = useCustomer()
  const theme = themeNormal(primaryColor)
  const { fontSize, handleToggleFontSize } = useFontSizeToggle()

  React.useEffect(() => {
    // restore localStorage fontSize
    handleToggleFontSize({ 'local.fontSize': fontSize })
  }, []) // eslint-disable-line

  const [windowWidth, setWindowWidth] = React.useState<number>(0)
  React.useEffect(() => {
    const handleResize = debounce(() => {
      setWindowWidth(window.innerWidth)
    }, 100)
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getBlobsProps = (dark: number, light: number) => ({
    primaryDark: hexToHsl(theme.primary, dark),
    primaryLight: hexToHsl(theme.primary, light),
  })
  const getBlobsByWindowWidth = () => {
    switch (true) {
      case !windowWidth:
        return
      case windowWidth < 600:
        return <BlobsSmallIcon {...getBlobsProps(90, 94)} />
      case windowWidth >= 600 && windowWidth < 1100:
        return <BlobsIcon {...getBlobsProps(94, 97)} windowSize="medium" />
      case windowWidth >= 1100:
      default:
        return <BlobsIcon {...getBlobsProps(94, 97)} />
    }
  }

  React.useEffect(() => {
    // use router for relative account links
    const handleClick = (e: any) => {
      const href = e.target && e.target.getAttribute('href')
      if (href && href.indexOf('/') === 0) {
        e.preventDefault()
        navigate(href)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [navigate])

  return (
    <Wrapper>
      {getBlobsByWindowWidth()}
      <TopSection>
        <CustomerLogo />
        <AccessibilityNavControls />
      </TopSection>
      {children || <Outlet />}
      {!['adhc', 'cirrusmd'].includes(key) && (
        <PoweredByDiv>
          <img alt="CirrusMD logo" src={cirrusLogo} />
        </PoweredByDiv>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div(
  {
    '.modal-open &': {
      filter: 'blur(5px)',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  ({ theme }) => ({
    ...MQ_TABLET({
      padding: theme.padding,
    }),
    padding: theme.paddingMobile,
  }),
)

const TopSection = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const PoweredByDiv = styled.div({
  '& img': {
    maxWidth: '100%',
    width: 180,
  },
  '&:before': {
    content: '"Powered By"',
    display: 'block',
    fontSize: 13,
    position: 'relative',
    textTransform: 'uppercase',
    top: 5,
  },
  position: 'relative',
  textAlign: 'center',
})

export default Layout
